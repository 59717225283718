import React from 'react';

function getClasses(rightChildren, colSize = 6) {
  let base = `flex-grow-1 d-flex`;
  if (rightChildren) {
    base += ` col-xs-12 col-lg-${colSize}`;
  }
  if (!rightChildren) {
    base += ' col-xs-12';
  }
  return base;
}

/* eslint-disable react/prop-types */
export default function SectionLayout({
  id,
  children,
  rightChildren,
  rightColSize,
  leftColSize,
  leftClasses,
  rightClasses,
  className,
  showBottomBorder = true,
  marginBottom,
  bgColor,
}) {
  return (
    <div
      id={id}
      className={`${
        marginBottom === false ? '' : 'mb-5 '
      }row section-layout-row${!showBottomBorder ? ' hide-border' : ''}${
        className ? ` ${className}` : ''
      }`}
      style={{ fontSize: '1rem', backgroundColor: bgColor || 'unset' }}
    >
      <div
        className={`${getClasses(rightChildren, leftColSize)}${
          leftClasses ? ` ${leftClasses}` : ''
        }`}
      >
        <div className="flex-grow-1">{children}</div>
      </div>
      {rightChildren ? (
        <div
          className={`${rightClasses ? `${rightClasses} ` : ''}${getClasses(
            rightChildren,
            rightColSize
          )}`}
          // className={getClasses(rightChildren, rightColSize)}
        >
          <div className="p-3 flex-grow-1">{rightChildren}</div>
        </div>
      ) : null}
    </div>
  );
}

export function SectionLayout2({ children, styles }) {
  return (
    <div className="container" styles={styles}>
      <div className="section-layout">{children}</div>
    </div>
  );
}
