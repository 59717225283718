/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { getSchemaOrgWebPage } from './utils';
import Facebook from './Facebook';
import Twitter from './Twitter';

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            image
            siteUrl
            twitter
            facebook
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const img = site.siteMetadata?.image;
  const siteUrl = site.siteMetadata?.siteUrl;
  const twitter = site.siteMetadata?.twitter;
  const facebook = site.siteMetadata?.facebook;
  const schemaOrgWebPage = getSchemaOrgWebPage({
    title: title || defaultTitle,
    defaultBanner: img,
    siteUrl,
    language: lang,
    headline: '',
    buildTime: '',
  });

  return (
    <>
      <Helmet
        title={title || defaultTitle}
        titleTemplate={title && defaultTitle ? `%s | ${defaultTitle}` : null}
        meta={meta}
      >
        <html lang={lang} />
        <meta name="description" content={metaDescription} />
        <meta name="image" content={img} />
        <link rel="canonical" href={site.siteMetadata?.siteUrl} />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgWebPage)}
        </script>
      </Helmet>
      <Facebook
        desc={metaDescription}
        image={`${siteUrl}${img}`}
        title={title || defaultTitle}
        type="website"
        url={siteUrl}
        locale={lang}
        name={facebook}
      />
      <Twitter
        title={title || defaultTitle}
        image={`${siteUrl}${img}`}
        desc={description}
        username={twitter}
      />
    </>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: '',
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
