// schema.org in JSONLD format
// https://developers.google.com/search/docs/guides/intro-structured-data
// You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

export function getSchemaOrgWebPage({
  siteUrl,
  headline,
  language,
  description,
  title,
  buildTime,
  defaultBanner,
}) {
  /* eslint-disable prettier/prettier */
  return {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    'url': siteUrl,
    headline,
    'inLanguage': language,
    'mainEntityOfPage': siteUrl,
    description,
    'name': title,
    'author': {
      '@type': 'Organization',
      'name': 'CultivateAI',
    },
    'copyrightHolder': {
      '@type': 'Organization',
      'name': 'CultivateAI',
    },
    'copyrightYear': '2021',
    'creator': {
      '@type': 'Organization',
      'name': 'CultivateAI',
    },
    'publisher': {
      '@type': 'Person',
      'name': 'CultivateAI',
    },
    'datePublished': '2021-01-20T10:30:00+01:00',
    'dateModified': buildTime,
    'image': {
      '@type': 'ImageObject',
      'url': `${siteUrl}${defaultBanner}`,
    },
  };
  /* eslint-enable prettier/prettier */
}
